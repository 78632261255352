.phaseButtons {
    display: flex;
    flex-wrap: wrap;
    max-width: 700px;
    margin: auto;
}

.phaseButtons button {
    width: calc(33% - 40px);
    font-size: 18px;
}

.phaseButtons button p {
    margin: 5px;
}