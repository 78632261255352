button.back-btn {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  padding: 5px;
  height: 30px;
  width: 30px;
}

button.back-btn svg {
  height: 100%;
  width: 100%;
  position: relative;
  top: -2px;
}