#keys {
  text-align: left;
  margin: 20px;
}

.key ul {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
}

.key ul li {
  margin: 0 20px 5px 0;
}

p.initials {
  position: absolute;
  top: -10px;
  right: 0;
  margin: 20px;
}

@media print {
  div.task h1 {
    font-size: 1.2em;
    margin: 0 0 15px 0;
  }
}