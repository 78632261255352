button.settings-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  padding: 5px;
  height: 30px;
  width: 30px;
}

button.settings-btn svg {
  height: 100%;
  width: 100%;
  position: relative;
  top: -2px;
}