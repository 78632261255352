#answersKey ul {
  display: flex;
  flex-wrap: wrap;
}
#answersKey ul li {
  width: 31%;
}

#answersKey ul li.correct:before { border: 3px solid var(--correct-green); }
#answersKey ul li.line:before {
  background: linear-gradient(to top right, var(--white-color) calc(50% - 3px), var(--grey-color), var(--white-color) calc(50% + 3px) );
  border: 3px solid var(--white-color);
}
#answersKey ul li.incorrect:before { border: 3px solid var(--incorrect-red); }

#answersKey ul li.correct:before,
#answersKey ul li.incorrect:before  {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: var(--white-color);
  margin: 5px 5px -7px 0;
}

#answersKey ul li .box {
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
}

#answersKey ul li .box svg {
  width: 100%;
  height: 100%;
  border: 3px solid var(--grey-color);
  background-color: var(--white-color);
  stroke: var(--grey-color);
}

#answersKey ul li span.boxText {
  margin-left: 12px;
  position: relative;
  top: -7px;
}

@media print {
  #answersKey ul li {
    width: 28%;
  }
}