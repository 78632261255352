.Grid {
    width: 100%;
    min-height: 450px;
    height: 65vh;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

@media print {
    .Grid .Cell:last-child {
        font-size: 0.9em;
    }
}