:root {
    /* Brand */
    --primary-color: #548C2B;
    --primary-color-light: #82D943;
    --primary-color-dark: #395922;
    --secondary-color: #F2B705;
    --secondary-color-light: #F0F2B6;
    --secondary-color-dark: #a17a03;
    --black-color: #000000;
    --white-color: #FFFFFF;
    --grey-color: #AAAAAA;
    --correct-green: #59BA63;
    --incorrect-red: #FA4F46;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    color: var(--black-color);
    background-color: var(--secondary-color-light);
    text-align: center;
    font-size: 18px;
}

.AppContainer {
    margin: 20px auto;
    max-width: 1000px;
}

.AppContainer .App {
    border: 10px solid var(--primary-color-light);
    border-radius: 20px;
    margin: 0 20px;
    position: relative;
}

input {
    font-size: 1em;
    padding: 10px;
}

button {
    background-color: var(--secondary-color);
    border: 1px solid var(--black-color);
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
    font-size: 22px;
    transition: 0.1s;
}

button:hover {
    background-color: var(--secondary-color-light);
}

button.selected {
    background-color: var(--secondary-color-dark);
    color: var(--white-color);
}

hr {
    border-top: 1px solid var(--primary-color-light);
}

/* Utilities */
.bg-primary { background-color: var(--primary-color); }
.bg-primary-light { background-color: var(--primary-color-light); }
.bg-primary-dark { background-color: var(--primary-color-dark); }
.bg-secondary { background-color: var(--secondary-color); }
.bg-secondary-light { background-color: var(--secondary-color-light); }
.bg-secondary-dark { background-color: var(--secondary-color-dark); }

/* Common Elements */
.phaseSquare {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 5px 5px -7px 0;
}

/** Print Styles **/
@media print {
    .AppContainer .App {
        border: none;
        border-radius: none;
        margin: 0;
    }

    button {
        display: none;
    }
}