.Cell {
    height: calc(100% / 12);
    width: calc(100% / 12);
    display: flex;
    justify-content: center;
    border-right: 1px solid var(--black-color);
    border-bottom: 1px solid var(--black-color);
    box-sizing: border-box;
    position: relative;
}

.Cell.light-color { color: var(--white-color); }
.Cell.dark-color { color: var(--black-color); }

.Cell.header {
    background-color: var(--black-color);
    color: var(--white-color);
}

.Cell div {
    align-content: center;
    display: grid;
    width: 100%;
    position: relative;
}

.Cell svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.Cell.correct svg#strikethrough { stroke: var(--correct-green); }
.Cell.incorrect svg#strikethrough { stroke: var(--incorrect-red); }

.Cell.correct, .Cell.Cell.incorrect { cursor: pointer; }
.Cell.correct:hover, .Cell.Cell.incorrect:hover { opacity: 0.7; }

.Cell.correct div { border: 3px solid var(--correct-green); }
.Cell.incorrect div { border: 3px solid var(--incorrect-red); }