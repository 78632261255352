div#phasesKey ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

div#phasesKey ul li {
  width: 31%;
}

@media print {
  div#phasesKey ul li {
    width: 28%;
  }
}